
// HEADER
//////////////////////////////
.branding {
	max-width: 100%; 
	width: 100%;
	height: auto;
	position: relative; 
	z-index: 1;
	margin: 0.5rem 0 0;
	max-width: 100px;
	@include breakpoint(tiny) {
		max-width: 100px;
	}
	@include breakpoint(small) {
		max-width: 100px;
	}
	@include breakpoint(medium) {
		max-width: 100px;
	}
	@include breakpoint(large) {
		max-width: 120px;
	}

	@include breakpoint(giant) {
		margin: 0.5rem 0;
		transition: width 300ms;
		max-width: 120px;
		img {
			width:100%;
			display: block; 
			transition: width 300ms;
		}

	}
	@include breakpoint(full) {
		transition: width 300ms;
		max-width: 150px;
		img {
			width:100%;
			display: block; 
		}
	}
}
.scroll {
	.branding {
	    img {
	    	width: rem(70px);
	    }
    }
}


.mobileBranding {
	z-index: 90;
	
	margin-top: 0rem;
	margin-bottom: 0rem;
    text-align: center;
    width: auto;
    position: absolute;
    top: 3rem;
    left: 0rem;
    width: 100%;
    //background: $light;
    @include breakpoint(tiny) {
    	top: 0rem;
    	text-align: left;
    }

	@include breakpoint(medium) {
		left: 0rem;
	}

	@include breakpoint(large) {
		left: 0rem;
	}
	
	@include breakpoint(giant) {
		display: none;
	}

	.branding {
		display: inline-block;
		margin: 0rem;
		background: $light;
		padding: 0.5rem 0.5rem;
	}
}


.brandCont {
	display: flex;
 	align-items: center;
 	height: 100%;
}
.desktBranding {
	display: none;
	@include breakpoint(giant) {
		display: block;
	}
}


.contactBar {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    display: none;
    flex-wrap: nowrap;
    width: 100%;
    margin-bottom: 1rem;
    

    @include breakpoint(giant) {
	    display: flex;
	    align-items: center;
	    flex-direction: row;
        justify-content: flex-end;
        margin-top: 0rem;
        margin-bottom: 0rem;
        height: 100%;
    }
    @include breakpoint(full) {
    	margin-left: 0rem;
    	transition: margin 200ms;
    }

    >span, >div {
    	font-size: rem(16px);
    	line-height: 120%;
    	color: lighten($dark,0%);
    	font-weight: 300;
    	display: inline-block;
		margin-left: 2rem;
    	@include breakpoint(huge) {
    		white-space: nowrap;
    		font-size: rem(17px);
    	}
    	@include breakpoint(full) {
    		font-size: rem(18px);
    	}

    	&.fbook {
    		display: inline-block;
    		padding-bottom: 4px;
    		a {
    			display: inline-block;

    			img {
    				width: rem(20px);
    			}
    		}
    	}
    	&.socialIcons {
    		margin-right: 0rem;
    		a {
    			display: inline-block;
    			margin-left: 1rem;
    			transform: translateY(1px);
    			transition:opacity 300ms;

    			svg {
    				width: rem(16px);
    				height: rem(16px);
    			}
    			&:hover {
    				opacity: 0.7;
    			}
    		}
    	}
        &.contactLink {
        	>a, >span {
        		transition: 300ms color;
			    &:before {
			        @extend .icon;
			        content: "\e941";
			        position: relative;
					transform: translateY(2px);
					margin-right: 3px;
			    }
	        	@include breakpoint(large) {
	        		 display: inline-block;
	        	}
        	}
        }
        &.locationLink {
        	>a, >span {
        		transition: 300ms color;
			    &:before {
			        @extend .icon;
			        content: "\ea3d";
			        position: relative;
					transform: translateY(2px);
					margin-right: 3px;
			    }
	        	@include breakpoint(large) {
	        		 display: inline-block;
	        	}
        	}
        }
        &.phoneLink {
        	>a ,>span {
        		transition: 300ms color;
			    &:before {
			        @extend .icon;
			        content: "\ea81";
			        position: relative;
					transform: translateY(2px);
					margin-right: 3px;
			    }
	        	@include breakpoint(large) {
	        		 display: inline-block;
	        	}
	        	&:hover {
	        		color: lighten($dark,0%);
	        	};
        	}
        }
        &.emailLink {
        	a {
        		transition: 300ms color;
        		color: $primary;
			    &:before {
			        @extend .icon;
			        content: "\e9b0";
			        position: relative;
					transform: translateY(2px);
					margin-right: 3px;
			    }
	        	@include breakpoint(large) {
	        		 display: inline-block;
	        	}
	        	&:hover {
	        		color: darken($dark,5%);
	        	};
        	}
        }
		&.times { //header
			>span {
				white-space: nowrap;
				display: block;
			    &:before {
			        @extend .icon;
			        content: "\e968";
			        position: relative;
					transform: translateY(2px);
					margin-right: 3px;
			    }
		    }
		}
    }

}

#header {
	position: relative;
	width: 100%;
	background: $darker;
	.headerSection {
	    position: relative;
	    width: 100%;
	    padding-top: 10rem;
	    padding-bottom: 0rem;
	    z-index: 1;

	    display: flex;
	    flex-direction: column;
	    align-items: center;
	    justify-content: center; 
	 
		z-index: 80;

		background: $darker;

		@include breakpoint(tiny) {
		    padding-top: 3rem;
		}
		@include breakpoint(medium) {
		    padding-top: 7rem;
		}

		@include breakpoint(giant) {
			margin-top: 10rem;
			padding: 10rem 1rem;
		}
		body:not(.index) & {
			display: flex;

			padding-top: rem(150px);
			padding-bottom: rem(50px);
	    	@include breakpoint(tiny) {
		    	padding-top: rem(200px);
		    	padding-bottom: rem(0px);
	    	}
	    	@include breakpoint(medium) {
		    	padding-top: rem(200px);
		    	padding-bottom: rem(50px);
	    	}
	    	@include breakpoint(large) {
		    	padding-top: rem(200px);
		    	padding-bottom: rem(50px);
	    	}
	    	@include breakpoint(giant) {
		    	padding-top: rem(100px);
		    	padding-bottom: rem(50px);
	    	}
	    	@include breakpoint(full) {
		    	padding-top: rem(150px);
		    	padding-bottom: rem(50px);
	    	}
	    	@include breakpoint(fullhd) {
		    	padding-top: rem(150px);
		    	padding-bottom: rem(50px);
	    	}

			@media screen and ( max-height: 700px ){
				@include breakpoint(giant) {
					padding-top: rem(50px);
					padding-bottom: rem(0px);
				}
			}
		}
	    .contactTeaser {
	        max-width: calc(100% - 0rem);
	        flex-basis: calc(100% - 0rem);
	        width: calc(100% - 0rem);
	        display: none;

	        padding: 2rem 1.3rem;

	        line-height: 1.4em;
	        margin-bottom: 1rem;
	        position: relative;
	        &:before {
	            @extend .icon;
	            content: "\ea3d";
	            font-weight: 300;
	            position: absolute;
	            font-size: rem(90px);
	            line-height: 100%;
	            left: rem(-20px);
	            top: rem(40px);
	            transform: translateY(-50%);
	            color: lighten($medium,5%);
	            z-index: -1;
	        }
	        &:nth-child(2) {
		        &:before {
		            @extend .icon;
		            content: "\e968";
		        }

		        dt {
		        	font-weight: 700;
		        }
	        };

	        @include breakpoint(tiny) {
	        	position: absolute;
	        	left: 100%;
	        	width: rem(350px);
	        	background: lighten($medium2,10%);
	        	color: $dark;
	        	box-shadow: $boxShadow;
	        	z-index: 1000;
	        	transition: left 300ms, z-index 300ms;
	            max-width: rem(350px);
	            flex-basis: rem(350px);
	            padding: 1.3rem 1.3rem;
	        	top: 10rem;
	        	&:nth-child(2) {
	        		top: 13rem;
	        	}
	        	&:before {
	        		background: $primary;
	        		color: $light;
	        		font-size: rem(30px);
	        		right: 100%;
	        		line-height: 100%;
	        		padding: 0.5rem 0.38rem 0.3rem 0.38rem;
	        		top: 0px;
	        		width: 44px;
	        		transform: translateX(-26px);

	        		@include breakpoint(small) {
	        			transform: translateX(-42px);
	        		}

	        		@include breakpoint(giant) {
	            		transform: translateX(-53px);
	            		font-size: rem(26px);
	            		width: 55px;
	            		top: rem(0px);
	        		}
	        	}
	        	&:hover, &:focus {
	            	left: calc(100% - 320px);
	            	z-index: 1001;
	            	@include breakpoint(giant) {
	            		left: calc(100% - 350px);
	            	}
	        	};

	        }
	        @include breakpoint(small) {
	        	top: 10rem;
	        	&:nth-child(2) {
	        		top: 13rem;
	        	}
	        }

	        @include breakpoint(giant) {
	        	display: block;
	        	margin-left: 0rem;
	        	margin-right: 0rem;
	            margin-bottom: 0px;
	            font-size: rem(18px);
	        	top: 8rem;
	        	&:nth-child(2) {
	        		top: 12rem;
	        	}
	        }
	        
	        @include breakpoint(full) {
	        	margin-bottom: 2rem;
	        }

	        @include breakpoint(fullhd) {
	            padding: 2rem 1.5rem;
	        }

			>span, >div {
				font-family: $mainFont;
				font-weight: 300;
				  font-size: rem($baseFontSize);
				  line-height: rem($baseLineHeight);
				display: block;
				padding-top: 0rem;
				color: $dark;
				&.title {
					font-weight: 300;
					margin-bottom: 1rem;
					line-height: 1.3em;
					b{
						font-weight: 700;
						display: block;
					}
				}
				&.clock {
					margin-top: 1rem;
					b {
						display: block;
					}
					span {
						display: block;
					}
					em {
						font-style: normal;
					}
				}
			}

			a {
				color: $dark;
				transition: 300ms color;
				&:hover {
					color: $primary;
				};
			}

		}


	    >.row {
	    	padding-top: rem(0px);
	    	padding-bottom: rem(0px);
	    	z-index: 81;

		    .col {
				flex-direction: column;
				justify-content: center;
				align-items: center;
		    	&:nth-child(1) {
		    		display: flex;
		    		flex-direction: column;
		    		justify-content: center;
		    		align-items: flex-start;
		    		flex-wrap: wrap;
		    		position: relative;

		    		@include breakpoint(giant,max) {
		    			order: 2;
		    		}

					.headerClaim {
						width: 100%;
						padding: rem(40px) 0.5rem rem(40px) 1rem;
						width: 100%;
						position: relative;
						text-align: left;

						display: flex;
						flex-direction: row;
						justify-content: center;
						flex-wrap: wrap;

						>div {
							width: 100%;
						}

						@include breakpoint(tiny) {
							z-index: 80;
							padding: rem(150px) rem(0px) rem(50px) 0rem;
						}
						@include breakpoint(medium) {
							padding: rem(120px) rem(0px) rem(50px) 0rem;
						}
						@include breakpoint(large) {
							padding: rem(120px) rem(0px) rem(50px) 0rem;
						}
						@include breakpoint(giant) {
							padding: rem(150px) rem(0px) rem(50px) 0rem;
						}
						@include breakpoint(full) {
							padding: rem(200px) rem(0px) rem(50px) 0rem;
						}
						@include breakpoint(fullhd) {
							padding: rem(200px) rem(0px) rem(50px) 0rem;
						}
						body:not(.index) & {
							display: flex;
						    	padding-top: rem(50px);
						    	padding-bottom: rem(50px);
					    	@include breakpoint(small) {
						    	padding-top: rem(50px);
						    	padding-bottom: rem(50px);
					    	}
					    	@include breakpoint(medium) {
						    	padding-top: rem(50px);
						    	padding-bottom: rem(50px);
					    	}
					    	@include breakpoint(large) {
						    	padding-top: rem(50px);
						    	padding-bottom: rem(50px);
					    	}
					    	@include breakpoint(giant) {
						    	padding-top: rem(50px);
						    	padding-bottom: rem(50px);
					    	}
					    	@include breakpoint(full) {
						    	padding-top: rem(50px);
						    	padding-bottom: rem(50px);
					    	}
					    	@include breakpoint(fullhd) {
						    	padding-top: rem(50px);
						    	padding-bottom: rem(50px);
					    	}
					    	@include breakpoint(giant,max) {
					    		display: none;
					    	}
						}

						.cms-article {
							p {
								text-align: center;
							}
						}

						//.headline
						.phoneLink, .emailLink {
							margin-right: 1rem;
							@include breakpoint(medium) {
								margin-right: 2rem;
							}
							a, span { 
								@extend .btn;
						        color: $dark;
						        width: auto;
						        font-weight: 400;
						        &:hover {
						            color: $primary;
						        }
							}
						}

						.btn {
					        &:hover {
					            color: $light;
					        }
						}
			    	}
		    	}
		    	&:nth-child(2) {
		    		@include breakpoint(large,max) {
		    			padding-left: 0;
		    			padding-right: 0;
		    		}
		    	}
		    }
	    }

	    .cmsBackend & {
	    	padding: 0;
	        .col {
	        	&:nth-child(1), &:nth-child(2) {
		        	display: none;
	        	}
	        }
	    }
	}
	body:not(.cms-template-edit) & {
		.headerImg {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			opacity: 0.6;
			@include breakpoint(giant) {
				opacity: 0.7;
			}
			figure {
				position: absolute;
				top: 0;
				left: 0;
				height: 100% !important;
				width: 100% !important;
			}
	        img{
	              @include object-fit(cover);
	              max-width: none;
	              width: 100% !important;
	              height: 100% !important;
	              margin-bottom: 0;
	              //max-height: 100%;         
	        }
        }
	}

	.cms-template-edit & {
		width:80%;

		@include breakpoint(large) {
			width:80%;
		}
	}
}

.headline {
	@extend .marginBottom;
	font-size: rem(25px);
	color: $light;
	text-transform: uppercase;
	font-weight: 700;
	font-family: $displayFont;
	line-height: 1.1em;
	letter-spacing: 0.05em;
	width: 100%;
	display: block;
	text-align: center;

	@include breakpoint(tiny) {
		margin-bottom: 2rem;
		font-size: rem(26px);
	}
	@include breakpoint(medium) {
		margin-bottom: 2rem;
		font-size: rem(30px);
	}
	@include breakpoint(giant) {
		margin-bottom: 2rem;
		font-size: rem(36px);
	}
	@include breakpoint(huge) {
		margin-bottom: 2rem;
		font-size: rem(42px);
	}

	@include breakpoint(full) {
		margin-bottom: 2rem;
		font-size: rem(50px);
	}

	body:not(.index) & {
		margin-bottom: 0rem;
	}

	span, em {
		display: block;
		letter-spacing: 0;
		font-style: normal;
		font-family: $mainFont;
		font-weight: 300;
		font-size: rem(18px);
		line-height: 1.2em;
		color: $light;
		padding-top: 0.5rem;
		@include breakpoint(full) {
			font-size: rem(20px);
		}
	    // &:before {
	    //     @extend .icon;
	    //     content: "\ea1f";
	    //     position: relative;
	    //     margin-right: rem(3px);
	    //     transform: translateY(2px);
	    //     display: inline-block;
	    // }
	}
}


 
// MAIN CONTENT
//////////////////////////////

main {
	display: block;
	// body:not(.index) & {
	// 	padding-top:rem(50px);
	// 	@include breakpoint(small) {
	// 		padding-top:rem(50px);
	// 	}
	// 	@include breakpoint(medium) {
	// 		padding-top:rem(80px);
	// 	}
	// 	@include breakpoint(large) {
	// 		padding-top:rem(100px);
	// 	}
	// 	@include breakpoint(huge) {
	// 		padding-top:rem(100px);
	// 	}
	// 	@include breakpoint(full) {
	// 		padding-top:rem(120px);
	// 	}
	// }

	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
	}

	ol,
	ul {
	      margin-top: 1rem;
	}

	p {
		a {
			font-weight: 300;
			transition: 300ms color;
		}
		strong {
			font-weight: 700;
		}
		em {
			font-style: normal;
		}
		>.btn {
			margin-bottom: 1rem;
		}
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList) {
		@extend .styledList;
	}

	img {
		margin-bottom:rem($baseGap*2);
	}

	.googleMaps {
		width: 100%;
		height: rem(400px);
		iframe {
			height: 100%;
		}

		@include breakpoint(medium) {
			height: rem(400px);
		}
	}
	.contentMap {
		width: 100%;
		height: rem(400px);
		position: relative;
		margin-bottom: 2rem;
		iframe {
			height: 100%;
			position: absolute;
			left: 0;
			width: 100%;
		}
		@include breakpoint(small) {
			height: 100%;
		}
	}
}

//MIXINS
@mixin section {
	padding-top:rem(50px);
	padding-bottom:rem(50px);

	@include breakpoint(small) {
		padding-top:rem(50px);
		padding-bottom:rem(50px);
	}
	@include breakpoint(medium) {
		padding-top:rem(80px);
		padding-bottom:rem(80px);
	}
	@include breakpoint(large) {
		padding-top:rem(100px);
		padding-bottom:rem(100px);
	}
	@include breakpoint(huge) {
		padding-top:rem(100px);
		padding-bottom:rem(100px);
	}
	@include breakpoint(full) {
		padding-top:rem(130px);
		padding-bottom:rem(130px);
	}

}
@mixin sectionSmall {
	padding-top:rem(30px);
	padding-bottom:rem(30px);
	@include breakpoint(small) {
		padding-top:rem(50px);
		padding-bottom:rem(50px);
	}
	@include breakpoint(huge) {
		padding-top:rem(100px);
		padding-bottom:rem(90px);
	}
}

@mixin sectionGap {
	margin-top:rem(50px);
	margin-bottom:rem(40px);

	@include breakpoint(small) {
		margin-top:rem(50px);
		margin-bottom:rem(50px);  
	}
	@include breakpoint(full) {
		margin-top:rem(114px);
		margin-bottom:rem(114px);
	}
}
.sectionGap {
    @include sectionGap;
}

@mixin sectionGapTop {
	margin-top:rem(60px);
	@include breakpoint(small) {
		margin-top:rem(100px);
	}
	@include breakpoint(huge) {
		margin-top:rem(120px);
	}
}
.sectionGapTop {
    @include sectionGapTop;
}

@mixin sectionGapBottom {
	margin-bottom:rem(60px);
	@include breakpoint(small) {
		margin-bottom:rem(100px);  
	}
	@include breakpoint(huge) {
		margin-bottom:rem(120px);
	}
}
.sectionGapBottom {
    @include sectionGapBottom;
}


@mixin sectionPadding {
	padding-top:rem(50px);
	padding-bottom:rem(50px);
	@include breakpoint(small) {
		padding-top:rem(50px);
		padding-bottom:rem(50px);
	}
	@include breakpoint(medium) {
		padding-top:rem(80px);
		padding-bottom:rem(80px);
	}
	@include breakpoint(large) {
		padding-top:rem(100px);
		padding-bottom:rem(100px);
	}
	@include breakpoint(huge) {
		padding-top:rem(120px);
		padding-bottom:rem(100px);
	}
	@include breakpoint(full) {
		padding-top:rem(114px);
		padding-bottom:rem(100px);
	}
}
.sectionPadding {
	@include sectionPadding;
}


.section, main .default-container {
	@include section;
	background: darken($light,0%);
	z-index: 5;
	position: relative;

	&.overXhidden {
		overflow-x: hidden;
	}
	&.mediumBg {
		background: darken($medium2,0%);
	}

	&.noGapBottom {
		padding-bottom: 0;
	}

	img {
	    margin-bottom: rem(20px);
	    @include breakpoint(medium) {
	    	margin-bottom: rem(40px);
	    }
	}

	.autoWidth {
		display: inline-block;
		img {
			width: auto;
			display: inline-block;
		}
	}
}
.sp-suffix-large-1 {
	@include breakpoint(large) {
		padding-right: 3rem;
	}
}

.sectionSmall {
	@include sectionSmall;
}
.section, .sectionSmall {
	position: relative;
	display: flex;
	margin-left:auto;
    margin-right:auto;
    position: relative;
    flex: 0 1 auto;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;


	&.overflowHidden {
		overflow: hidden;
	}
	&.noGapTop {
		padding-top:0;
	}
	&.noGap {
		padding-top:0;
		padding-bottom:0;
	}
	&.noGapBottom {
		padding-bottom:0;
	}
	&.spPadBtm {
		@include breakpoint(large) {
			padding-bottom:rem(70px);
		}
		@include breakpoint(full) {
			padding-bottom:rem(100px);
		}
	}

	&.itemsCenter {
		align-items: center;
	}

	.row {
		&.spGapBottom {
			padding-bottom:rem(30px);
			@include breakpoint(small) {
				padding-bottom:rem(40px);
			}
			@include breakpoint(huge) {
				padding-bottom:rem(100px);
			}
		}
	}

	.addInfo {
		position: relative;
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row; 
	    &:before {
			content:"";
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: row; 
			position: absolute;
			top: 0rem;
			left: calc(50% - 50vw);
			right: 0rem;
			height: 100%;
			width: auto;
			z-index: -2;
			opacity: 1;
			transition:opacity 300ms;
			background: $secondary;
			@include breakpoint(medium) {
				right: 1rem;
			}
	    };
	    p {
	    	color: $light;
	    }
		ul {
			margin-bottom: 0;
			li {
				color: $light;
				font-size: rem(20px);
				line-height: 1.2em;
				margin-bottom: 1rem;
				display: block;
				font-family: $displayFont;
				padding-right: 1rem;
				padding-left: 2rem;
				&:last-child {
					margin-bottom: 0;
				};
			    &:before {
			        @extend .icon;
			        content: "\ea1f";
			        position: relative;
			        text-indent: -2rem;
			        transform: translateY(1px);
			        color: $light;
			    }
				span {
					font-family: $mainFont;
					display: block;
					font-size: rem(18px);
					line-height: 1.2em;
					padding-left: rem(0px);
					@include breakpoint(full) {
						padding-left: rem(5px);
					}
				}
				@include breakpoint(medium) {
					font-size: rem(25px);
					padding-left: 3rem;
				}
				@include breakpoint(large) {
					font-size: rem(30px);
					padding-left: 3rem;
				}
				@include breakpoint(full) {
					font-size: rem(40px);
					margin-bottom: 2rem;
					padding-left: 4rem;
					span {
						font-size: rem(25px);
					}
				}
			}
		}
	}

    .imgcolFull {
        background:$medium;
		z-index: -1;
		position: relative;
		height: rem(300px);
		width: 100%;
		margin-bottom: 2rem;
		margin-top: 0rem;

        @include breakpoint(medium) {
	        position: absolute;
	        margin: 0;
	        height: 100%;
	        display: block;
			left: calc(50% - 50vw);
			right: calc(50% - 50vw);

	        top: 0;
	        width: auto;
        }
    }
	.imgCover{
		@include object-fit(cover);
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0%;
		z-index: -1;
		width: 100%;
		max-width: none;
		height: 100%;
		max-height: 100%;
		margin-bottom: 0;
	}

	.col {
		&.smPaddingLeft {
			@include breakpoint(medium) {
				padding-left: 4rem;
			}
			@include breakpoint(full) {
				padding-left: 6.4rem;
			}
			h2, .h2 {
		        align-items: flex-start;
			}
		}
		&.smPaddingRight {
			@include breakpoint(medium) {
				padding-right: 4rem;
			}
			@include breakpoint(full) {
				padding-right: 6.4rem;
			}
			h2, .h2 {
		        align-items: flex-start;
			}
		}
	}
}

@keyframes myfirst
{
	from {transform: translateX(-20px)}
	to {transform: translateX(-10px)}
}

@-webkit-keyframes myfirst / 
{
	from {transform: translateX(-20px)}
	to {transform: translateX(-10px)}
}


.teaserCtn {
	margin-top: rem(0px);
	margin-bottom: 0rem;
	//@extend .section;
	//background: $medium2;
	padding: 1rem;
	position: relative;

	@include breakpoint(giant) {
		padding: 1rem;
	}

    &:before {
        @extend .icon;
	        @include breakpoint(full,max) {
	        content: "\ea1f";
	        position: absolute;
	        animation: myfirst 1s infinite linear;
			-webkit-animation: myfirst 1s infinite linear;  
	        top: calc(100% - 20px);
	        right: 3rem;
	        margin-right: rem(3px);
	        display: inline-block;
	        font-size: rem(30px);
	        @include breakpoint(medium) {
	        	top: calc(100% - 20px);
	        }
	        @include breakpoint(large) {
	        	display: none;
	        }
        }
    }

	.row {
		display: flex;
		overflow-x: auto;
		//flex-wrap: nowrap;
		max-width: 100%;

		@include breakpoint(full,max) {
			overflow-y: auto;
			flex-wrap: nowrap;
		}

		@include breakpoint(full) {
			overflow: visible;
			padding: 0;
		}

		.col {
			body:not(.cms-page-edit) & {
				position: relative;
				margin-top: 1rem;
				margin-bottom: 2rem;
				figure{
					position: absolute;
					width: auto !important;
					left: 1rem;
					right: 1rem;
					height: 100% !important;

			        img{
			              @include object-fit(cover);
			              max-width: none;
			              width: 100% ;
			              height: 100% ;
			              margin-bottom: 0;
			              //max-height: 100%;         
			        }
				}

				@include breakpoint(large) {
					margin-top: 1rem;
					margin-bottom: 1rem;
				}
				@include breakpoint(full) {
					width: 25%;
					max-width: 25%;
				}

				.cms-article {
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: flex-end;
					background-size: cover;
					background-position: center center;
					height: 0;
					padding-bottom: 90%;
					position: relative;
					text-decoration: none;
					font-size: rem(24px); 
					@include breakpoint(large) {
						font-size: rem(30px);
					}
					@include breakpoint(giant) {
						box-shadow: 0px 0px 43px 0px rgba(0,0,0,0.06);
						font-size: rem(36px);
					}

					@include breakpoint(full) {
						font-size: rem(44px);
					}

					&:before {
						content: '';
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						background: linear-gradient(to bottom, rgba($medium,0) 0%,rgba($medium,0) 30%,rgba($darker,0.90) 100%);
						opacity: 1;
						transition: opacity .5s;
					}

					&:after {
						content: '';
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						background-color: $primary;
						opacity: 0;
						transition: opacity .5s;
					}

					a {
						position: absolute;
						left: 50%;
						transform: translateX(-50%);
						bottom: rem(0px);
						z-index: 2;
						color: $light;
						font-weight: 800;
						text-transform: uppercase;
						font-family: $displayFont;
						display: flex;
						justify-content: center;
						align-items: flex-end;
						transition: transform .5s, bottom .5s;
						width: 100%;
						height: 100%;
						letter-spacing: 0.05em;
						padding-left: 0rem;
						padding-bottom: 1rem;
						transition: opacity .5s;
						text-align: center;

						&:after {
							content: '';
							position: absolute;
							left: 0;
							top: 0;
							width: 100%;
							height: 100%;
							background-color: $primary;
							opacity: 0;
							transition: opacity .5s;
						}
						@include breakpoint(huge) {
							bottom: rem(0px);
							padding-left: 0rem;
							padding-bottom: 1rem;
						}
					}

					p {
						&:nth-child(2) {
							position: absolute;
							top: 2rem;
							width: calc(100% - 2rem);
							left: 1rem;
							opacity: 0;
							transition: opacity .5s;
							color: $light;
							z-index: 1;
							font-size: rem(18px);

							@include breakpoint(giant) {
								width: calc(100% - 4rem);
								left: 2rem;
								top: 3rem;
							}
							&:after {
								content: "mehr erfahren";
								display: block;
								margin-top: 0.2rem;
								color: lighten($secondary,30%);
							}
						}
					}

					&:hover {
						&:before {
							opacity: 0;
						}

						&:after {
							opacity: .8;
						}

						a {
							opacity: 0.8;
						}
						p {
							&:nth-child(2) {
								opacity: 1;
							}
						}

					}
				}
			}

		}

	}
}

.contentBox {
	padding-top: 0rem;
	padding-bottom: 0rem;
	@include breakpoint(large) {
		padding: 0rem 3rem;
	}
	@include breakpoint(fullhd) {
		padding: 0rem 4rem;
	}
	&.noBottomGape {
		padding-bottom: 0;
	}
}


.sideImgSection {
	position: relative;
	overflow: hidden;
}
.sideImage {
	position: relative;

	h1, h2, h3, .h1, .h2, .h3 {
		text-align: left;
	}
	.col {
		position: relative;
	}


	&.txtRight {
		margin-bottom: 2rem;
		&:before {
	    	@include breakpoint(medium) {
		        position: absolute;
		        margin: 0;
		        height: 100%;
		        display: block;
				right: 0;
				left: calc(50% - 0.0vw + 1rem);
		        top: 0;
		        width: auto;
	    	}
		}
		&.color {
			&:before {
				background: $fourth;
			}

		    .h3 {
		    	color: $light;
		    	span {
		    		color: $light;
		    	}
		    }
		    p {
		    	color: $light;
		    	&:last-child {
		    		margin-bottom: 0;
		    	}
		    	&.highlight {
		    		margin-bottom: 0.6rem;
		    		white-space: normal;
		    		font-size: rem(22px);
		    	}
		    }
		    a {
		    	color: $light;
		    	text-decoration: underline;
		    	transition: 300ms color;
		    	&:hover {
		    		color: $primary;
		    	};
		    	&.btn {
		    		color: $light;
			    	&:hover {
			    		color: $light;
			    	};
		    	}
		    }


		}
	}
	&.txtLeft {
		margin-bottom: 2rem;
		&:before {
	    	@include breakpoint(medium) {
		        position: absolute;
		        margin: 0;
		        height: 100%;
		        display: block;
				left: 0;
				right: calc(50% - 0.0vw + 1rem);
		        top: 0;
		        width: auto;
	    	}
		}
		&.color {
			&:before {
				background: $fourth;
			}

		    .h3 {
		    	color: $light;
		    	span {
		    		color: $light;
		    	}
		    }
		    p {
		    	color: $light;
		    	&:last-child {
		    		margin-bottom: 0;
		    	}
		    	&.highlight {
		    		margin-bottom: 0.6rem;
		    		white-space: normal;
		    		font-size: rem(22px);
		    	}
		    }
		    a {
		    	color: $secondary;
		    	transition: 300ms color;
		    	&:hover {
		    		color: $primary;
		    	};
		    	&.btn {
		    		color: $light;
			    	&:hover {
			    		color: $light;
			    	};
		    	}
		    }

		}
	}

	// >.col {
	// 	@include section;
	// }
}


ul.flexList {
    padding-left: 0;
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    &.center {
    	justify-content: center;
    	li {
    		margin: 0.5rem;
    	}
    }

    li {
        list-style: none;
        position: relative;
        margin-bottom: rem(20px) !important;
        font-size: rem(18px);
        line-height: rem($baseLineHeight);
        border-radius: $rborder;
        text-align: left;
        text-transform: none;
        font-family: $mainFont;
        color: $dark;
        margin-right: 1rem;
        display: inline-block; 
        background: darken($medium2,3%);
        padding: 10px 15px 9px;
        // padding: 1rem 2rem !important;

        @include breakpoint(medium) {
	        padding: 10px 20px 9px;
        }


        &:before {
           display: none!important;
        }

        a {
        	color: darken($primary,15%);
        	strong {
        		color: $dark!important;
        		color: darken($primary,15%)!important;
        	}
        	&:hover {
        		color: $dark!important;
	        	strong {
	        		color: $dark!important;
	        	}
        	}
        }

        strong {
            display: inline-block;
            color: $dark;
            font-weight: 700;
        }
    }
}

ul.flexLinkList {
    padding-left: 0;
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    li {
        list-style: none;
        position: relative;
        margin-bottom: rem(20px) !important;
        font-size: rem(18px);
        line-height: rem($baseLineHeight);
        border-radius: $rborder;
        text-align: left;
        text-transform: none;
        font-family: $mainFont;
        color: $dark;
        padding-left: 0rem !important;
        margin-right: 1rem;
        display: inline-block; 
        // padding: 1rem 2rem !important;


        &:before {
           display: none!important;
        }

        a {
	        background: darken($medium2,0%);
	        padding: 10px 15px;
        	color: darken($primary,15%);
        	transition:300ms background;
        	display: inline-block;
        	strong {
        		color: $dark!important;
        		color: darken($primary,15%)!important;
        	}
        	&:hover {
        		color: $light!important;
        		background: darken($primary,0%);
	        	strong {
	        		color: $light!important;
	        	}
        	}
        }

        strong {
            display: inline-block;
            color: $primary;
            font-weight: 700;
        }
    }
}



.imgText {
	margin-bottom: 1rem;
	display: block;
	img {
		margin-bottom: 0;
	}
	p {
		margin-top: 0.2rem;
		margin-bottom: 0;
		font-size: rem(16px);
	}
}

// SUBPAGES OVERVIEW
.overview {
  display: flex; 
  flex-wrap:wrap; 
  justify-content: flex-start;
  width: 100%;

	>li {
		padding-left: 0;
		&:before {
			display: none !important;
		};
	}


  > * {
    
    background: rgba($third,.6);
    margin: rem($baseGap);
    transition:300ms background;
    padding: 0;
    margin: 0;
    margin-bottom: 1rem;
    width: calc(100% - 0rem);
    @include breakpoint(tiny) {
    	width: calc(33% - 1rem);
    	margin-right: 1rem;
    }
    @include breakpoint(tiny) {
    	width: calc(33% - 1rem);
    }
    
    &:nth-child(odd) {
    	background: rgba($third,.6);
    };
    &:hover {
      background: rgba($third,.99); 
      transition:300ms background;
      a {
        color:$light;
        transition: 300ms color;
      }
    }
    &:before {
      display: none;
    }
    .sub {
    	display: none;
    }
    a {
      display: block;
      text-decoration: none;
      padding:1.5em 1em;
      text-align: center;
      font-size: rem(18px);
      font-weight: 700;
      color:$light;
      font-family: $displayFont;
      @include breakpoint(giant) {
      	font-size: rem(20px);
      }
    }
  }
}

.gutgemacht {
	background-color: #f8f8f8;
	display: block;
	color: $dark;
	a {
		display: block;
		background: #008b95;
		padding: 1rem;
		margin-bottom: 1rem;
		text-align: center;
		span {
			display: block;
			text-align: center;
		}
		>img {
			max-width: 171px;
			margin-bottom: 0;
		}
	}

	.gutGemacht {
	    width: 100%;
	    height: 320px;
	    //max-width: 300px;
	    overflow: hidden;


	    html {
	    	background-color: #f8f8f8 !important;
	    }
	}
}


#privacyPolicy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}
}

.spBox {
	padding: rem(40px) rem(20px) rem(20px);
	background-color: rgba($primary,0.8);
	color: $light;
    margin-bottom: 2rem;
    position: relative;
    z-index: 1;

    &.noColor {
		background: darken($medium2,4%);
		color: $dark;
		display: block;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: flex-end;
		flex-wrap: wrap;

	    .h1, .h2, h2, .h3 {
	    	color: $light;
	    	span {
	    		display: inline-block;
	    		font-size: 1em;
	    		color: $light;
	    	}
	    }
	    p {
	    	&:last-child {
	    		margin-bottom: 0;
	    	}
	    	&.highlight {
	    		margin-bottom: 1rem;
	    		white-space: normal;
	    		font-size: rem(22px);
	    		color: $primary;
	    	}
	    }
	    p >a {
	    	color: $dark;
	    	text-decoration: underline;
	    	&:hover {
	    		color: $primary;
	    	};
	    	&.btn {
	    		color: $dark;
		    	&:hover {
		    		color: $dark;
		    	};
	    	}
	    }

	    .btn {
	    	margin-bottom: 0;
	    }

		.btnContact {
    		display: block;
    		.phone, .email  {
    			@extend .btn; 
    			display: inline-block;
    			white-space: nowrap;
    			margin: 0.5rem 0 0 0;
    			
    			@include breakpoint(small, max) {
    				width: 100%;
    			}

    		}
    		.phone {	
    			background: $primary;
    			margin-bottom: 1rem;
			    &:before {
			        @extend .icon;
			        content: "\ea81";
			        position: relative;
			        
					transform: translateY(1px);
			    }
			    &:hover {
			    	background: $primary;
			    	cursor: default;
			    }
    		}

    		.email {
		        background: $secondary;
		        width: auto;
		        color: $light;

    			@include breakpoint(small) {
    				margin-right: 1rem;
    			}
			    &:before {
			        @extend .icon;
			        content: "\e9b0";
			        position: relative;
					transform: translateY(1px);
			    }
		        &:hover {
		            background: lighten($secondary,10%);
		            color: $light;
		        }
    		}
		}
    }

    @include breakpoint(small) {
    	padding: rem(40px) rem(30px) rem(30px);
    }
    @include breakpoint(huge) {
    	padding: rem(40px) rem(50px) rem(35px);
    }
    @include breakpoint(full) {
    	padding: rem(65px) rem(70px) rem(65px);
    }
    &.padd {
		padding: rem(40px) rem(40px) rem(35px);
    }

    .h1, h2, h3, .h3 {
    	color: $light;
    	span {
    		color: $light;
    	}
    }
    ul {
    	&:last-child {
    		margin-bottom: 0;
    	}

    	li {
    		&:before {
    			color: lighten($third,50%) !important;
    		};
    	}
	}
    p {
    	&:last-child {
    		margin-bottom: 0;
    	}
    	&.highlight {
    		margin-bottom: 0.6rem;
    		white-space: normal;
    		font-size: rem(20px);
    		color: lighten($third,50%);
    	}
    }
    p >a {
    	color: $light;
    	text-decoration: underline;
    	&:hover {
    		color: lighten($primary,30%);
	    	text-decoration: none;

    	};
    	&.btn {
    		color: $light;
	    	&:hover {
	    		color: $light;
	    	};
    	}
    }
}

.bigPicSection {
    position: relative;
    width: 100%;
    padding-top: 4rem;
    padding-bottom: 4rem;
    //overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
 
	z-index: 1;
	position: relative;

	background-color: $darker;

	.fullImg {
		position: fixed;
		width: auto !important;
		left: 0rem;
		right: 0rem;
		height: 100% !important;
		top: 0;
		opacity: 0.8;
		z-index: -1;

        img{
              @include object-fit(cover);
              max-width: none;
              width: 100% ;
              height: 100% ;
              margin-bottom: 0;
              //max-height: 100%;        
        }
	}

	@include breakpoint(small) {
		padding: rem(80px) 1rem rem(80px) 1rem;
	}
	@include breakpoint(medium) {
		padding: rem(80px) 1rem rem(80px) 1rem;
	}
	@include breakpoint(large) {
		padding: rem(140px) 1rem rem(140px) 0rem;
	}
	@include breakpoint(giant) {
		padding: rem(140px) 1rem rem(140px) 0rem;
	}
	@include breakpoint(full) {
		padding: rem(160px) 1rem rem(160px) 0rem;
	}
    >.row {
    	padding-top: rem(0px);
    	padding-bottom: rem(0px);
    	position: relative;
		.cms-page-edit & {
			z-index: 80;
		}
	    .col {
	    	&:nth-child(1) {
	    		flex-direction: column;
	    		justify-content: center;
	    		align-items: center;
	    		flex-wrap: wrap;
	    		position: relative;

				//.claim
				.headline, .h1, .h2 {
					margin-bottom: 2rem;
					display: block;
					color: $light;
					@include breakpoint(large) {
						margin-bottom: 2rem;
					}
				}
				p {
					text-align: center;
					a {
						&:hover {
							color: $light;
						};
					}
				}
	    	}
	    }
    }
}



// FOOTER
//////////////////////////////

#pageFooter {
	z-index: 10;
	position: relative;
	overflow: hidden;
	@include section;
	margin-bottom: 0;
	background: rgba($light, 1);
	body:not(.index) & {
		padding-top: 0;
	}
	.cmsBackend & {
		z-index: 0;
	}
    >.row {
    	&.containerRow {
    		.le-editable-area {
    			width: 100%;
    		}
		    >.col {
			    color: $dark;
	            text-transform: none;
				position: relative;
	    		display: flex;
	    		flex-direction: row;
	    		align-items: flex-start;
	    		justify-content: flex-start;
	    		flex-wrap: wrap;
	    		padding-bottom: 0;
	    		z-index: 2;

	            .footerContent {
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: flex-start;
					flex-wrap: wrap;
					width: 100%;
					padding-top: 2rem;
					@include breakpoint(small) {
						justify-content: space-between;
					}
					@include breakpoint(giant) {
						padding-top: 2rem;
						padding-bottom: 2rem;
					}

					@include breakpoint(full) {
						padding-top: 5rem;
						padding-bottom: 5rem;
						padding-left: 2rem;
					}

	            	.footerBox  {
			            background: none;
			            width: auto;
			            margin-top: 1rem;
			            width: 100%;

			            @include breakpoint(medium) {
			            	margin-top: 0;
			            }

			            &:nth-child(2) {
			            	padding-left: 0;
			            }
			            &:first-child {
			            	width: 100%;
			            	flex-basis: 100%;
			            	margin-bottom: 0rem;
			            	.client {
			            		@extend .h2;
			            		color: $dark;
			            		margin-bottom: 1rem;
			            		text-align: center!important;
			            		display: block;
			            		font-size: rem(19px);
			            		&:before {
			            			display: none;
			            			@include breakpoint(tiny) {
			            				top: 100%;
			            				display: inline-block;
			            			}
			            		};
			            		@include breakpoint(medium) {
			            			font-size: rem(20px);
			            			text-align: left!important;
			            		}
			            		@include breakpoint(giant) {
			            			font-size: rem(22px);
			            		}
			            	}
			            };

			            @include breakpoint(medium, max) {
			            	text-align: center;
			            }

				        @include breakpoint(medium) {
				        	margin-left: 0.0rem;
				        	margin-right: 0.0rem;
				        }
				        @include breakpoint(large) {
				            margin-bottom: 0rem;
				            padding-right: 0;
				        }


		        		>span, >div {
		        			display: block;
		        			width: 100%;
		        			flex-basis: 100%;
		        			color: lighten($dark,0%);

		        			em {
		        				font-style: normal;
		        			}

		            		&.address {
		            			display: block;
		            			margin-bottom: 1rem;
		            		}
							&.times { //footer
					            @include breakpoint(large, max) {
					            	margin-bottom: 1rem;
					            	margin-top: 1rem;
					            }
								color: lighten($dark,0%);
								span {
									display: block;
									font-weight: 300;
									display: block;
								}

								dt {
									@include breakpoint(giant) {
										display: block;
									}
								}
								dd {
									margin-bottom: 0.1rem;
								}
								dd, dt {
									display: block;
								}
							}
		        		}

				    	.socialIcons { //footer//
				    		margin-right: 0rem;
				    		a {
				    			display: inline-block;
				    			margin-top: 1rem;
				    			transform: translateY(1px);
				    			transition:opacity 300ms;
				    			color: $dark;

				    			svg {
				    				width: rem(20px);
				    				height: rem(20px);
				    				transform: translateY(4px);
				    			}
				    			&:hover {
				    				opacity: 0.7;
				    				color: $primary;
				    			}
				    		}
				    	}

			        	a {
			        		text-decoration: none;
			        		font-weight: 300;
			        		color: darken($primary,5%);
			        		transition: 300ms color;
							&:hover {
								color: rgba($dark,0.9);
							}
						}

						&.footerInner {
							position: relative;
							margin-top: 1rem;
							padding-top: 1rem;
							border-top: 1px solid $medium2;
							
							@include breakpoint(medium) {
								margin-top: 1rem;
								padding-top: 1rem;
							}
							@include breakpoint(giant) {
								margin-top: 1rem;
								padding-top: 1rem;
							}

							p {
								color: $light;
							}

							.origiSize {
								max-width: 247px;
								display: inline-block;
								margin-top: 0.5rem;
								margin-bottom: 2rem;
							}
						}

						//////////////////////////////////////////////
						// naviAdd
						.naviAddCont {
							li {
								margin-right: 0rem;
								a {
									color: $dark;
									&.active, &:hover {
										color: darken($primary,5%)!important;
									}
								}
							}
						}
	            	}
	            }
		    }
	    }
    }

    .mapsContainer {
	    margin-top: 2rem;
	    width: 100%;
	    max-width: 100%;
	    height: rem(350px);
	    position: relative;
	    //box-shadow: em(5px, $baseFontSize) em(5px, $baseFontSize) em(59px, $baseFontSize) rgba(black, 0.125);
	    @include breakpoint(medium) {
	    	margin-top: 0rem;
	    	height: 100%;
	    }
    }

	.googlemaps {
	    height: 100%;
	    width: 100%;
	    padding-bottom: 0;
	    position: relative;
	    background: rgba($light, 0.6);

	    //filter: grayscale(100%);
	    position: relative;
	    margin-bottom: 0rem;

	    div {
	    	height: 100%;
	    }

	}
}

.partnerCol {
	position: relative;
	margin-bottom: 2rem;
    &:before {
        content:'';
        background: lighten($light,1%);
        position: absolute;
        top:0;
        left:0;
        right:0;
        margin:auto;
        width: calc(100% - #{rem($baseGap*2)}); 
        height: 100%;
        z-index: -1;
        box-shadow: $boxShadow;
		border: 1px solid $medium2;
    }
	.partner {
		text-align: center;
		margin: 1rem auto;
		padding: 1rem;

		h2 {
			font-size: rem(18px);
			text-align: center;
			margin-bottom: .4rem;
		}

		.image {
			width: auto;
			margin-bottom: 1rem;
		}

		a {
			&:hover {
				text-decoration: underline;
			} ;
		}
	}
}


.downloadBoxes {
	display: flex;
	flex: 0 1 auto;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: flex-start;
	align-items: stretch;
	padding-left: 0;
	padding-right: 0;
	.loop-child {
		@extend .contentBox;
	    z-index: 1;
	    position: relative;
	    padding:rem($baseGap*2) rem($baseGap*2);  
	    margin: 1rem 0;

	    @include breakpoint(giant) {
		    padding:rem($baseGap*2) rem($baseGap*2);  
	    	margin-top: 0;
	    }
	    > *:last-child {
	    	margin-bottom: 0;
	    }

	    max-width: calc(100% - 0rem);
	    flex-basis: calc(100% - 0rem);
	    width: calc(100% - 0rem);
	    margin-bottom: 1rem;
	    @include breakpoint(small) {
	        max-width: calc(50% - 1rem);
	        flex-basis: calc(50% - 1rem);
	        width: calc(50% - 1rem);
	    	margin-left: 0.5rem;
	    	margin-right: 0.5rem;
	    }
	    body:not(.cms-page-edit) & {
		    @include breakpoint(medium) {
		        max-width: calc(50% - 0rem);
		        flex-basis: calc(50% - 0rem);
		        width: calc(50% - 0rem);
		    	margin-left: 0rem;
		    	margin-right: 0rem;
		        margin-bottom: 0px;
		    }
		    @include breakpoint(giant) {
		        max-width: calc(33.333% - 0rem);
		        flex-basis: calc(33.333% - 0rem);
		        width: calc(33.333% - 0rem);
		    	margin-left: 0rem;
		    	margin-right: 0rem;
		        margin-bottom: 0px;
		    }
	    }

	    @include breakpoint(medium,max) {
	    	h4 {
	    		font-size: rem(20px);
	    	}
		    p {
		    	font-size: rem(16px);
		    }
	    }
	    
		a, p {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
		    text-align: center;
		    text-decoration: none;
		    text-transform: uppercase;
		    font-family: $displayFont;
		    font-weight: 700;
		    letter-spacing: 0.05em;
		    flex-wrap: wrap;
		    &:before {
		        @extend .icon;
		        content: "\ea1f";
		        position: relative;
		        margin-right: rem(0px);
		        transform: translateY(-4px) rotate(90deg);
		        width: 100%;
		    }
		    &:after {
		        content:'';
		        background: lighten($light,0%);
		        position: absolute;
		        top:0;
		        left:0;
		        right:0;
		        margin:auto;
		        width: calc(100% - #{rem($baseGap*2)}); 
		        height: 100%;
		        z-index: -1;
		        box-shadow: $boxShadow;
		        transition: 300ms background;
		    }
			&:hover {
			    &:after {
			        background: lighten($primary,0%);
			    }
		    	img {
		    		opacity: 0.5;
		    	}
	    	}
	    };

	    p {
		    &:before {
		        @extend .icon;
		        content: "\ea1f";
		        position: relative;
		        margin-right: rem(0px);
		        transform: translateY(-4px) rotate(90deg);
		        width: 100%;
		    }
	    }
	}
}

.energyProducts {
	display: flex;
	flex: 0 1 auto;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: flex-start;
	align-items: stretch;
	padding-left: 0;
	padding-right: 0;
	.loop-child {
		@extend .contentBox;
	    z-index: 1;
	    position: relative;
	    padding:rem($baseGap*2) rem($baseGap*2);  
	    margin: 1rem 0;

	    @include breakpoint(giant) {
		    padding:rem($baseGap*2) rem($baseGap*2);  
	    	margin-top: 0;
	    }
	    > *:last-child {
	    	margin-bottom: 0;
	    }

	    max-width: calc(100% - 0rem);
	    flex-basis: calc(100% - 0rem);
	    width: calc(100% - 0rem);
	    margin-bottom: 1rem;
	    @include breakpoint(small) {
	        max-width: calc(50% - 1rem);
	        flex-basis: calc(50% - 1rem);
	        width: calc(50% - 1rem);
	    	margin-left: 0.5rem;
	    	margin-right: 0.5rem;
	    }
	    @include breakpoint(giant) {
	        max-width: calc(70% - 1rem);
	        flex-basis: calc(70% - 1rem);
	        width: calc(70% - 1rem);
	    	margin-left: 0.5rem;
	    	margin-right: 0.5rem;
	    }
	    body:not(.cms-page-edit) & {
		    @include breakpoint(medium) {
		        max-width: calc(50% - 0rem);
		        flex-basis: calc(50% - 0rem);
		        width: calc(50% - 0rem);
		    	margin-left: 0rem;
		    	margin-right: 0rem;
		        margin-bottom: 1rem;
		    }
		    @include breakpoint(giant) {
		        max-width: calc(50% - 0rem);
		        flex-basis: calc(50% - 0rem);
		        width: calc(50% - 0rem);
		    	margin-left: 0rem;
		    	margin-right: 0rem;
		    }
	    }

	    @include breakpoint(medium,max) {
	    	h4 {
	    		font-size: rem(20px);
	    	}
		    p {
		    	font-size: rem(16px);
		    }
	    }
	    
		p {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
		    text-align: center;
		    text-decoration: none;
		    text-transform: uppercase;
		    font-family: $displayFont;
		    font-weight: 400;
		    letter-spacing: 0.05em;
		    flex-wrap: wrap;
		    color: $medium;
		    strong {
		    	font-weight: 700;
		    	color: $primary;
		    	display: block;
		    	width: 100%;
		    }
		    &:before {
		        @extend .icon;
		        content: "\ea1f";
		        position: relative;
		        margin-right: rem(0px);
		        transform: translateY(-4px) rotate(90deg);
		        width: 100%;
		    }
		    &:after {
		        content:'';
		        background: lighten($light,0%);
		        position: absolute;
		        top:0;
		        left:0;
		        right:0;
		        margin:auto;
		        width: calc(100% - #{rem($baseGap*2)}); 
		        height: 100%;
		        z-index: -1;
		        box-shadow: $boxShadow;
		        transition: 300ms background;
		    }
			&:hover {
			    &:after {
			        background: lighten($medium2,0%);
			    }
		    	img {
		    		opacity: 0.5;
		    	}
	    	}
	    };

	    p {
		    &:before {
		        @extend .icon;
		        content: "\ea1f";
		        position: relative;
		        margin-right: rem(0px);
		        transform: translateY(-4px) rotate(90deg);
		        width: 100%;
		    }
	    }
	}
}

//TEAM
.partnerRow {
	display: flex;
	flex: 0 1 auto;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: flex-start;
	align-items: stretch;
	.col {
		@extend .contentBox;
	    z-index: 1;
	    position: relative;
	    padding:rem($baseGap*2) rem($baseGap*2);  
	    margin:rem($baseGap*2) 0;
	    padding-bottom: 3rem;
	    
		display: flex;
		flex-direction: row;
		align-items: center;
		flex-wrap: wrap;
		justify-content: center;

	    @include breakpoint(giant) {
	    	padding:rem($baseGap*2) rem($baseGap*2.4);
	    	padding-bottom: 3rem;
	    	margin-top: 0;
	    }
	    > *:last-child {
	    	margin-bottom: 0;
	    }

	    .highlight {
	    	font-size: rem(20px);
	    }
	    &:before {
	        content:'';
	        background: lighten($light,1%);
	        position: absolute;
	        top:0;
	        left:0;
	        right:0;
	        margin:auto;
	        width: calc(100% - #{rem($baseGap*2)}); 
	        height: 100%;
	        z-index: -1;
	        box-shadow: $boxShadow;
	    }
		figure {
			display: block;
			a {
				display: block;

				img {

					width: auto;
					max-width: 150px;
					margin-bottom: 1rem;
				}
			}
		}
		text-align: center;

		.persTitle {
			margin-bottom: 0.5rem;
			font-size: 1rem;
		}
	    @include breakpoint(medium,max) {
	    	h4 {
	    		font-size: rem(20px);
	    	}
		    p {
		    	font-size: rem(16px);
		    }
	    }
	    
		a {
			&:hover {
		    	img {
		    		opacity: 0.5;
		    	}
	    	}
	    };
	}
}
//downloads
.downloadBox {
    z-index: 1;
    position: relative;
    padding:rem($baseGap*2) rem($baseGap*2);  
    margin:rem($baseGap*2) 0;
    padding-bottom: 3rem;
    @include breakpoint(giant) {
    	padding:rem($baseGap*2) rem($baseGap*2.4);
    	margin-top: 0;
    }
    
    &:before {
        content:'';
        background: darken($light,1%);
        position: absolute;
        top:0;
        left:0;
        right:0;
        margin:auto;
        width: calc(100% - #{rem($baseGap*2)}); 
        height: 100%;
        z-index: -1;
        box-shadow: $boxShadow;
    }

	.btn {
		margin-bottom: 0rem;
	}
}

.col {
	&.beforeBG {
		position: relative;

		margin-bottom: 2rem;
		@include breakpoint(small) {
			margin-top: 2rem;
		}
		@include breakpoint(large) {
			margin-top: 0rem;
		}
		&:before {
			content: "";
	        position: absolute;
	        margin: 0;
	        height: 100%;
	        display: block;
			left: 1rem;
			right: 1rem;
	        top: 0;
	        width: auto;
	        z-index: -1;
	        background: $third;
		}
		&.grey {
			&:before {
				content: "";
		        position: absolute;
		        margin: 0;
		        height: 100%;
		        display: block;
				left: 1rem;
				right: 1rem;
		        top: 0;
		        width: auto;
		        z-index: -1;
		        background: $medium;
			}
			.spBox {
				background: $medium;
			}
		}
	}
}

.contactBox {
	padding: 2rem 1rem 1rem;
	display: block;
	@include breakpoint(medium) {
		padding: 3rem 3rem 1rem;
	}
	.anfahrt &, .standort & {
		@include breakpoint(medium) {
			padding: 3rem 1rem 1rem;
		}
		@include breakpoint(huge) {
		padding: 3rem 3rem 1rem;
		}
	}
	h2 {
		color: $light;
	}
	>span {
		font-family: $mainFont;
		font-weight: 300;
		line-height: 1.4em;
		display: block;
		padding-top: 0rem;
		color: $light;
		&.title {
			font-weight: 700;
			margin-bottom: 1rem;
		}

		a {
			color: $light;
			transition: 300ms color;
			&:hover {
				color: darken($light,15%);
			};
		}
		&.clock {
			margin-top: 1rem;
			line-height: 1.4em;
			color: darken($medium,10%);
			b {
				display: block;
			}
			span {
				display: block;
			}
			em {
				font-style: normal;
				display: block;
				font-weight: 700;
			}
		}
	}
}

.flexGalery {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	.col {

		max-width: 50%;
		-ms-flex-preferred-size: 50%;
		flex-basis: 50%;
		width: 50%;
		@include breakpoint(medium) {
			max-width: 25%;
			-ms-flex-preferred-size: 25%;
			flex-basis: 25%;
			width: 25%;
		}

	}
}
.indexImg {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	a {
		display: inline-block;
		max-width: calc(50% - 1rem);
		-ms-flex-preferred-size: calc(50% - 1rem);
		flex-basis: calc(50% - 1rem);
		width: calc(50% - 1rem);
	}
}


// Timeline / width:100%
#timeline {
    width: calc(98% - 1rem);
    margin: 0px;
    margin-left: 1rem;
    position: relative;
    padding: 0;
    transition:color .4s, background .4s;
    @include breakpoint(small) {
        margin: 0px auto rem(30px);
        margin-left: 0rem;
        padding: 0px 0px;
    }
    @include breakpoint(large) {
    	width: calc(100% - 0rem);
    }


    &:before {
        content:"";
        width: 3px;
        height: 100%;
        background:$primary;
        left: 0;
        top: 0;
        position: absolute;
    }

    &:after {
        content: "";
        clear: both;
        display: table;
        width: 100%;
    }
    
    .timeline-item {
        margin-bottom: 50px;
        position: relative;
        @extend .clearfix;

        .timeline-icon {
            content: "";
            position: absolute;
            top: 20px;
            left: -8px;
            display: block;
            width: 19px;
            height: 19px;
            border: 4px solid $primary;
            border-radius: 0;
            background: $light;
            @include breakpoint(small) {
                top: 30px;
            }
            @include breakpoint(huge) {
                top: 30px;
            }
            @include breakpoint(giant) {
                margin-left: rem(-0px);
            }
        }

        .timeline-content {
            width: 90%;
            background: lighten($medium2,20%);
            border-radius: $rborder;
            padding: 20px 10px 10px 10px;
            float: right;
            border:none;
            box-shadow: $boxShadow;

            @include breakpoint(small) {
                padding: 30px 30px 30px 30px;
            }
            @include breakpoint(large) {
            	width: calc(100% - 4rem);
            }
            @include breakpoint(huge) {
                padding: 30px 30px 10px;
            }
            &.jobs {
	            @include breakpoint(huge) {
	                padding: 30px 30px 40px;
	            }
            }


            h2 {
                margin: 0px 0px rem($baseLineHeight) 0px;
                border-radius:3px 3px 0 0;
                text-align: left;
                font-size: rem(18px);
                font-weight: 700;
                color: $primary;
                margin-bottom: rem(20px);

                @include breakpoint(small) {
                    font-size: rem(20px);
                }
                @include breakpoint(large) {
                    font-size: rem(25px);
                }
                &:after {
                    margin: rem(10px) 0 0;
                }
            }

            h1, h2, h3, h4 {
            	@include breakpoint(small) {
            		font-size: rem(22px);
            	}
                &:before {
                    display: none;
                }
            }

            p {
                font-size: rem(18px);
            }


            iframe, .lightbox-image {
                position: relative;
                margin-bottom: rem(40px);
                border: rem(2px) solid lighten($medium,25%);
                border-radius: $rborder;
            }

            .smallText {
                font-size: rem(13px);
                line-height: rem(20px);
            }

            .gallery {
                justify-content: center;
                margin-top: rem(0px);
                @include breakpoint(medium) {
                    justify-content: flex-start;
                }
            }

            >.row {
                >.col {
                    padding: 0;

                    @include breakpoint(small) {
                        padding-left: 1.125rem;
                        padding-right: 1.125rem;
                    }       
                }
            }
        }
    }
}

.modalGallery {
	margin-top: 2rem;

	&.center {
		justify-content: center;
	}
}
.col {
	>.modalGallery {
		&:first-child {
			margin-top: 0;
		}
	}
}



