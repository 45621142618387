* {
  margin: 0;
  padding: 0;

  &,
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

::selection {
}
::-moz-selection {
}

// DO NOT set font-size and line-height here!
// Adjust $baseFontSize and $baseLineHeight in config
html {
  background: $light;
  box-sizing: border-box;
  color: $dark;
  font-family: $mainFont;
  font-weight: 300;
  font-size: 90% * ($baseFontSize / 16px);
  hyphens: manual;
  line-height: rem($baseLineHeight);
  scroll-behavior: smooth;

  /* disable text resize in landscape. e.g. on iphone */
  text-size-adjust: none;
  @include breakpoint(giant) {
    font-size: 100% * ($baseFontSize / 16px);
    line-height: rem($baseLineHeight);
  }
}

body {
  opacity: 1 !important;  
  font-size: rem($baseFontSize);
  line-height: rem($baseLineHeight);
  &:not(.javascript) {
    * {
      transition:none !important;
    }
  }
}

.preload *{
  transition: none !important; 
}


iframe,
[data-iframe] {
  border: none;
  width: 100%;
}

address {
  font-style:normal;
}

/**
 * Headlines
 */


/**
 * Headlines
 */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  @extend .marginBottom;
  text-rendering: optimizeLegibility;
  hyphens: auto;
  font-weight: 700;
  line-height: 1.3;
  font-family: $displayFont;
  letter-spacing: 0.05em;
    display: block;
  text-transform: uppercase;

  &.highlight {
      margin-bottom: rem(20px);
      line-height: 120%;
      font-size: 1.6rem;
  }
}

h1, .h1 {
    text-rendering: optimizeLegibility;
    hyphens: auto;
    font-weight: 700;
    line-height: 1.3;
    font-family: $displayFont;
    letter-spacing: 0.05em;
    display: block;
    text-align: center;
    font-size: rem(26px);
    @include breakpoint(tiny) {
        font-size: rem(30px);
    }
    @include breakpoint(small) {
        font-size: rem(30px);
        margin-bottom: 1rem !important;
    }
    @include breakpoint(large) {
        font-size:$h1Size;
        margin-bottom: 3rem !important;
    }
    @include breakpoint(giant) {
        font-size:$h1Size;
        margin-bottom: 4rem !important;      
    }


    >span {
        letter-spacing: 0;
        display: block;
        font-weight: 300;
        font-family: $mainFont;
        margin-bottom: 0.125em;
        line-height: 1.3em;
        font-size: 0.8em;
        @include breakpoint(full) {
          font-size: 0.7em;
        }
    }


    &.onlybold, &.highlight {
        font-size: $baseFontSize;
        font-weight: 700;
        font-family: $mainFont;
        margin-bottom: rem(6px)!important;
        padding-bottom: rem(0px);
        line-height: 120%;
        text-align: left;
        text-transform: none;
        align-items: flex-start;
        color: $primary;
        span {
            font-size: 1em;
        }
        &:after, &:before {
            display: none;
        }
    }
}

h2, .h2 {
    margin-bottom: rem(20px)!important;
    letter-spacing: rem(4px);
    text-transform: uppercase;
    @include breakpoint(full) {
        margin-bottom: rem(20px)!important;
    }
    .impressum & {
      margin-bottom: rem(10px)!important;
      &:after {
        display: none;
      };
    }
}

h2, .h2, h3, .h3 {
  font-size: rem(25px);
  font-weight: 700;
  margin-top: 0;
  line-height: 120%;
  font-family: $displayFont;
    >span {
        letter-spacing: 0;
        display: block;
        font-weight: 300;
        font-family: $mainFont;
        margin-bottom: 0.125em;
        line-height: 1.3em;
        font-size: 0.8em;
        @include breakpoint(full) {
          font-size: 0.7em;
        }
    }
    @include breakpoint(small) {
        font-size: rem(24px);
        margin-bottom: rem(20px) !important;
    }
    @include breakpoint(medium) {
      font-size: rem(24px);
    }
    @include breakpoint(giant) {
        margin-bottom: rem(20px) !important;
    }
    @include breakpoint(full) {
        font-size:$h2Size;
    }
    &.alignLeft {
      margin-bottom: 1rem !important;
    }

    &.highlight {
        font-size: $baseFontSize;
        text-align: left;
        margin: 0;
        padding: 0;
        margin-bottom: 1rem !important;
        text-align: left;
        align-items: flex-start;
        color: $medium;
        font-size: 1.6rem;
        font-family: $mainFont;
        span {
            font-size: 1.6rem;
        }
        &:before {
            display: none;
        }
        &:after {
            display: none;
        }
        padding-bottom: 0;
    }

    .impressum &, .standort &, .anfahrt &, .datenschutzerklaerung & {
        margin-top: rem(0px);
        padding-bottom:rem(0px);
        margin-bottom:rem(10px)!important;
        border-bottom: none;
        font-family: $displayFont;
        text-align: left;
        color: $medium;
        &:after {
            display: none;
        }
        @include breakpoint(large) {
            font-size:rem(25px);
        }
    }
}

h3 {
  &.spMargin {
    margin-top: 2rem;
  }
}

h4, .h4 {
    font-size:rem(20px);
    margin-bottom:  $baseGap*0.5;
    font-weight: 700;
    line-height: 130%;
    font-family: $displayFont;
    @include breakpoint(medium) {
      font-size:rem($h4Size);
    }
}

h5, .h5 {
    font-size:rem($h5Size);
    font-weight: 700;
    line-height: 130%;
}

h6, .h6 {
    font-size:rem($h6Size);
    font-weight: 700;
    line-height: 130%;
}

/**
 * Links
 */
a {
  color: darken($primary,5%);
  word-wrap: break-word;
    text-decoration: none;

  &:focus,
  &:hover,
  &:active {
    color: darken($secondary,40%);

    >img {
      opacity: 0.7;
    }
  }

  img {
    border: none;
    transition: 300ms opacity;
  }

  &[href^="tel"] {
    color: inherit;
    text-decoration: underline;
  }
}

a:not(.btn):focus,
[tabindex]:focus {
  outline: $outline;
  outline-offset: rem(5px);
}

hr, .hr {
  display: flex;
  background: darken($medium2,5%);
  border: none;
  clear: both;
  height: rem(1px);
  margin: 2rem 0 3rem;
  flex-basis: 100%;
  @include breakpoint(large) {
    margin: 4rem 0 6rem;
  }
    &.sm {
        margin: 0.5rem 0;
    }
    &.small-hr {
        margin: 0.5rem 0;
        background: none;
    }
}
ul,
ol {
  list-style: none;


  &.styledList {
    &.spList {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      font-weight: 300;
      li {
        color: $dark;
        background-color: lighten($medium2,3%);
        padding-top: 0.6rem;
        padding-bottom: 0.6rem;
        padding-left: 2rem;
        padding-right: 1.4rem;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        line-height:  1.4em;
        border-radius: rem(5px);
        strong {
          font-weight: 700;
        }
      }
    }
    &.textList {
      display: block;
      font-size: rem(16px);
      font-weight: 300;
        @include breakpoint(full) {
            font-size: rem(18px);
        }
        li {
          color: $dark;
          background-color: $medium2;
          padding-top: 1rem;
          padding-bottom: 1rem;
          padding-left: 2rem;
          padding-right: 2rem;
          font-weight: 700;
          span {
            font-weight: 300;
            display: block;
          }
        }
    }
    li {
      padding-left: rem(15px);
      margin-bottom: rem(8px);
        strong {
          font-weight: 700;
        }
      &:before {
        @extend .icon;
        @extend .icon-angle-right;
        text-indent: rem(-15px);
        color: $secondary;
      }
    }

    ul {
      margin-left: 1rem;
      margin-bottom: 0;
    }
  }
    &.nolink-list, &.highlight {
        margin-left: 0;
        margin-bottom: rem(20px);

        li {
            padding-left: rem(22px);
            list-style: none;
            position: relative;
            color: $dark;
            background: lighten($medium2,1%);
            padding: rem(20px) rem(30px) rem(17px);
            margin-bottom: rem(10px);
            padding-left: rem(40px) !important;
            font-size: rem(16px);
            line-height: 1.4em;
            border-radius: $rborder;
            text-align: left;
            font-size: rem(15px);
            font-weight: 300;
            strong {
              font-weight: 700;
            }
            @include breakpoint(giant) {
             font-size: rem(16px);
            }
            &:before {
                @extend .icon;
                @extend .icon-angle-right;
                position: absolute;
                font-size: 2em;
                font-weight: 300;
                left: rem(30px);
                top: calc(50% - 1px);
                transform: translateY(-50%);
                color: $secondary;
            }
            strong {
                display: inline-block;
            }
            a {
              &:hover {
                color: $dark;
              };
            }
            &.listTitle {
                font-weight: 700;
                &:before {
                    display: none;
                }
                            
                display: block;

                ul {
                    margin-top: 0.8rem;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                    li {
                        font-weight: 300;
                    }
                }
            }

            ul {
                margin-left: 0rem;
                li {
                    color: lighten($dark,20%);
                    padding-left: rem(22px);
                    padding: rem(5px) rem(20px);
                    line-height: 120%;
                    &:before {
                        @extend .icon;
                        @extend .icon-angle-right;
                        color: darken($medium2,20%);
                    }
                }
            }
        }

        ul {
            margin-left: 1rem;
            margin-bottom: 0;
        }
    }
}

dl {
  &.styledList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      background: darken($medium2,3%);
      padding: rem(15px);
    }

    dt {
      padding-bottom: 0;
      font-weight: 700;
    }

    dd {
      padding-top: 0;
      strong {
        padding-bottom: 1rem;
      }

      &:not(:last-child) {
        margin-bottom: rem(10px);
      }
    }
  }

  &.floatList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      vertical-align: top;
      float: left;
    }

    dt {
      padding-right: 0.5rem;
      width: 40%;
      clear: left;
    }

    dd {
      width: 60%;
    }
  }
}

ol {
  list-style-type: none;
  counter-reset: item;

  li {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    display: table;
    counter-increment: item;

    &:before {
      content: counters(item, ".") ". ";
      display: table-cell;
      padding-right: 0.3em;
    }
  }

  ol {
    margin-bottom: 0;

    li {
      &:before {
        content: counters(item, ".") " ";
      }
    }
  }
}

/*
 * responsive images
 */

img {
  vertical-align: bottom;
  float: none;
  height: auto;
  max-width: 100%;
  width: 100%;

  &[src^='http://cms.'], &[src^='https://cms.'] {
    max-width: none;
    width: auto;
  }

  [data-rel] &,
  .lightbox-image & {
    margin-bottom: 0;
  }

  &[data-src] {
    @extend %lazyloading;
  }
}

/**
 * Tables
 */

table {
  display: table;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  text-align: left;
  font-size: rem($baseFontSize);

  @include breakpoint(small, max) {
    overflow-x: auto;
    display: block;
  }

  caption {
    text-align: left;
    padding: rem($baseGap) rem($baseGap) rem(25px);
    display: table-caption;
    font-weight: 700;
    font-size: 1.2rem;
  }

  thead {
    border-collapse: collapse;
    display: table-header-group;
  }

  tbody {
    width: 100%;
    overflow-x: scroll;
    display: table-row-group;

    tr {
      padding: rem($baseGap) 0;

      &:not(:last-child) {
        border-bottom: rem(1px) solid $medium2;
      }
    }
  }

  tr {
    display: table-row;
  }

  th,
  td {
    padding: rem($baseGap);
    display: table-cell;
    vertical-align: top;
  }

  @include breakpoint(small, max) {
    td {
      min-width: 50vw;
    }
  }
}
