// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: 	giant; 	// break from mobile to desktop navigation
$barHeight: 50px; 	// height for quickbar
$barHide: 	false; 	// if bar hides on scroll mobile (true, false)
$navHover:	true;	// hover functionality for desktop navigation

$transition: 		300ms; 	// transition for some stuff

// NAVIGATION
//////////////////////////////////////////////

$navBg: $light; // Navigation Background
$navColor: $dark; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: 1em rem($baseGap);
	text-decoration: none; 
	color: $navColor;

    font-weight: 700;
    font-family: $displayFont;
}
%buttonHover {
	// Link Hover
	color: $light;
	background-color: $primary;
}
%buttonActive {
	// Link Active
	color: $light;
	background-color: $secondary;
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	transform: rotateY(180deg);

	// @include breakpoint($break) {
	// 	transform:rotate(90deg);
	// }
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 50px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $dark; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;

	transition: 300ms transform;

	nav {
		@include ul {
			padding-top: 1rem;
			&.navi {
				li {
					@include navButtons {
						@include navButton;
						border-radius: $radiusBorder $radiusBorder 0 0 ;
					}
					@include has-sub {
						@include sub {
							background: $navBg;

							&:before {
								padding: 1em rem($baseGap);
								background-color: darken($navBg, 6%);
							}
						}
					}
					@include navBack {
					}
				}
			}
		}
	}
}

.naviMain li.sitemap {
    display: none;
}



// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	#navigation {
		background-color: $light;
		transition: 300ms background-color,300ms box-shadow;
		.cms-page-edit & {
			background-color: $light;
		}
    	.scroll & {
            -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.40);
            -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.40);
            box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.40);
            background-color: darken($light,0%);
    	}

    	.naviRow {
    		position: relative;
    		padding-top: 0.5rem;
    		padding-bottom: 0.5rem;
    		z-index: 20;
	        @include breakpoint(giant) {
	        	&:before {
	        		content: "";
			        position: absolute;
			        display: block;
			        height: 100%;
					left: calc(50% - 50vw);
					right: calc(50% - 50vw);
					background-color: darken($primary,5%);
			        top: 0;
			        width: auto;
			        z-index: -1;
	        	}
	        }
    	}

		nav {
			//border-top: 1px solid $medium2;
			ul {
				padding-top: 0rem;
				&.navi {
					li {
						a, span {
							padding: rem(7px) rem(7px) rem(7px);
							//background: none;
							color: $light!important;
							transition:background-color 300ms;
							&.active, &:hover {
								background-color: darken($secondary,5%);
								color: lighten($primary,40%);
							}
						}
						&.has-sub {
							a, span {
								padding: rem(7px) rem(7px) rem(7px);
							}
							.sub {
							}
						}
						&.navBack, &.navItem  {
							display: none;
						}


						.sub {
							background:$primary!important;
							position: absolute;
							top:120%;
							left:0;
							min-width: 100%;
							opacity: 0;
							visibility:hidden;
							pointer-events: none;
							transition:opacity $transition,visibility $transition, top $transition;
							padding: rem(13px) rem(10px) rem(12px);

							li {
								padding: 0; margin: 0;

								a, span {
									transition:background $transition;
									padding:rem(8px) rem(14px);
									font-size:rem(16px);

									&:before {
										display: none;
									}

									&.subToggler {
										&:after {
											content: map-get($iconMap, angle-right); 
										};
									}
								}


								&.active > a,
								&.active > span,
								a.active,
								span.active,
								&:hover > a,
								&:hover > span,
								a:hover,
								span:hover {
									background-color: lighten($secondary,0%);
									color:$light;
								}

								@if $navHover {
							 		body.desktop & {
										&:hover {
											> .sub {
												top:0%; opacity: 1;
												visibility: visible;
												pointer-events: auto;
											}
										}

										.navBack {
											display: none;
										}
									}
								};
							}

							&.current {
								top:100%; opacity: 1;
								visibility: visible;
								pointer-events: auto;
							}

							&.open {
								top:100%;
							}

							.sub {
								top:20%; left:100%;

								&.current, &.open {
									top:0;
								}
							}
						}

						@if $navHover {
					 		body.desktop & {
								&:hover {
									> .sub {
										top:100%; opacity: 1;
										visibility: visible;
										pointer-events: auto;
									}
								}

								.navBack {
									display: none;
								}
							}
						}


					}
				}
			}
		}
	}
}




// Quickbar Styles
////
#quickbar {
	@extend %animatedTransform;
	background-color: $dark;
	color: $barColor;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;
	width: 100%;
	right: 0;
	//border-radius: 0 0 $radiusBorder $radiusBorder;
	@include breakpoint(tiny) {
		right: 0;
		left: auto;
		width: auto;
		//border-radius: 0 0 0 $radiusBorder;
	}

	.phone,
	.email {
		font-size: rem(0px);
		&:before {
			font-size: rem(20px);
			top:rem(2px);
		};
		@include breakpoint(tiny) {
			&:before {
				font-size: rem(18px);
				margin-right: rem(30px); 
				top:rem(0px);
			};
		}
		@include breakpoint(small) {
			&:before {
				font-size: rem(18px);
				top:rem(0px);
				margin-right: rem(30px); 
			};
		}
	}
	.phone{
		cursor: auto;
		color: $light;
		&:hover, &:active, &:focus {
		    background: $secondary;
		    color: $light;
		 }
		&:before{
	        @extend .icon;
	        content: "\ea81";
			font-size: rem(22px);
			position:relative;
			transform: translateY(5px);
		}
	}

	.email{
		text-decoration: none; 
		color: $light;
		&:before{
	        @extend .icon;
	        content: "\e9b0";
			font-size: rem(22px);
			position:relative;
			transform: translateY(5px);
		}
	}
    .waLink {
    	>a ,>span {
    		margin-right:rem(22px);
    		transition: 300ms color;
    		color: $light;
			display: flex;
			align-items: center;
   	        svg {
			    display: inline-block;
			    margin-right: 5px;
			}
			span {
				display: inline-block;
			    font-size: 15px;
			    line-height: 2;
			}
			@include breakpoint(small) {
				margin-right:rem(30px);
			}
        	@include breakpoint(large) {
        		 display: inline-block;
        	}
        	&:hover {
        		color: lighten($dark,20%);
        	};
    	}
    }
	.fbook {
		display: inline-block;
		padding-bottom: 2px;
		margin-right:rem(20px);
		a {
			display: inline-block;

			img {
				width: rem(30px);
			}
		}
	}

	.branding {
		height: rem(32px);
		width: auto;
		display: block;
		img {
			max-height: 100%;
			width: auto;
		}
	}

	@include breakpoint($break) {
		display: none;
	}
}

