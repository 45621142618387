// Define some variables for collapser usage
$collapser-class: ".collapser";
$collapser-gaps-lr: 1.2rem;
$collapser-gaps-tb: 1rem;
$collapser-color: $secondary;
$collapser-dark: $primary;

// Dont Edit Inside the mixin, overwrite styles in @include collapser
@mixin collapser {

    #{$collapser-class} {
        border: 2px solid rgba($primary, 0.2);
        padding: $collapser-gaps-tb 78px 0rem $collapser-gaps-lr;
        position: relative;
        cursor: pointer;
        font-size: 20px;
        transition: background 0.3s, border-color 0.3s, color 0.3s;
        color: white;
        background: $primary;
        margin-bottom: 1.5rem;
        // @extend %animated-transform;

        p {
            margin-bottom: 0;
        }

        &:hover {
            background: $secondary;
            border-color: $secondary;
            color: $light;

            &:after,
            &:before {
                background: $light;
            }
        }

        &:not(.collapser-initialized) {
            background: none;
            padding:0;
            color: black;

            &:after, &:before {
                display:none;
            }
        }

        &:after, &:before {
            background: white;
            width:25px;
            height: 3px;
            content:"";
            position: absolute;
            right: $collapser-gaps-lr;
            transition: all 300ms;
        }
        &:after {
            top: 50%;
        }
        &:before {
            transform: rotate(90deg);
            top:50%;
        }

        &.active {
            &:before {
                transform: rotate(-135deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        }

        & + * {
            overflow:hidden;
            transition: max-height 300ms;

            &.limited {
                overflow-y:scroll;
                margin-bottom: 1rem;
            }
        }

        @content;
    }
}

// Add your custom shine here ;)
@include collapser {
    @include breakpoint(small, max) {
        font-size: 18px;
        padding: 1rem 50px 1rem 1.2rem;
    
        &:after, &:before {
            width:15px;
            height: 2px;
        }
    }
}